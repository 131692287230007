import { useNavigation } from '@refinedev/core';
import { Button, Card, Skeleton } from '@scalingworks/react-admin-ui';
import { Content } from '@scalingworks/refine-react-admin';
import * as React from 'react';

import { resourceNames } from '../resource-names';
import { PriceTierTable } from './components/price-tier-table';
import { rateSetupHelpers } from './rate-setup-helpers';

export const rateSetupResourceShowPage = rateSetupHelpers.defineShowPage({
	fields: [],
	component: ({ queryResult: { isLoading, data } }) => {
		const navigateTo = useNavigation();

		const defaultTiers = React.useMemo(() => data && data.data.defaultTieredPrice.tiers, [data]);
		const dynamicTiers = React.useMemo(() => data && data.data.defaults, [data]);

		return (
			<Content
				title="Rate setup"
				extra={
					<Button
						onClick={() => navigateTo.edit(resourceNames.rateSetup, 'show')}
						disabled={isLoading}
						variant="solid"
						size="sm"
						className="min-w-[6rem]"
					>
						Edit
					</Button>
				}
			>
				<div className="flex flex-col gap-5">
					<Card className={isLoading ? 'animate-pulse' : ''}>
						<Card.Header bordered>Default Pricing</Card.Header>
						<Card.Body>
							<div className="sm:flex sm:items-center sm:gap-6">
								<div className="text-lg font-medium">
									{data ? (
										data.data.defaultTieredPrice.name
									) : (
										<Skeleton height="medium" width="medium" />
									)}
								</div>
								<div>
									{isLoading ? (
										<PriceTierTable.Skeleton className="w-full" />
									) : (
										(defaultTiers && defaultTiers.length > 0 && (
											<PriceTierTable tiers={defaultTiers} className="w-full" />
										)) ||
										'-'
									)}
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card>
						<Card.Header bordered>Dynamic Pricing</Card.Header>
						<Card.Body>
							<table className="w-full">
								<tbody>
									{dynamicTiers &&
										dynamicTiers.map((tier, index) => {
											const [firstPrice, ...slotPrices] = tier.timeSlotPrices;

											return (
												<React.Fragment key={index}>
													<tr>
														<td
															className="py-1 border-b border-gray-200"
															rowSpan={tier.timeSlotPrices.length || undefined}
														>
															{tier.days && tier.days.join(', ')}
														</td>
														<td className="py-1 border-b border-gray-200">
															{firstPrice.from} - {firstPrice.to}
														</td>
														<td className="py-1 border-b border-gray-200">
															{firstPrice.price.name}
														</td>
														<td className="py-1 border-b border-gray-200">
															{firstPrice.price.tiers && firstPrice.price.tiers.length > 0 ? (
																<PriceTierTable className="w-full" tiers={firstPrice.price.tiers} />
															) : (
																'-'
															)}
														</td>
													</tr>
													{slotPrices.map((slotPrice, index) => (
														<tr key={index}>
															<td className="py-1 border-b border-gray-200">
																{slotPrice.from} - {slotPrice.to}
															</td>
															<td className="py-1 border-b border-gray-200">
																{slotPrice.price.name}
															</td>
															<td className="py-1 border-b border-gray-200">
																{slotPrice.price.tiers && slotPrice.price.tiers.length > 0 ? (
																	<PriceTierTable
																		className="w-full"
																		tiers={slotPrice.price.tiers}
																	/>
																) : (
																	'-'
																)}
															</td>
														</tr>
													))}
												</React.Fragment>
											);
										})}
									{isLoading && (
										<>
											<tr>
												<td>
													<Skeleton height="medium" width="medium" />
												</td>
												<td>
													<div className="flex items-center gap-2">
														<Skeleton /> - <Skeleton />
													</div>
												</td>
												<td>
													<Skeleton />
												</td>
												<td>
													<PriceTierTable.Skeleton className="w-full" />
												</td>
											</tr>
											<tr>
												<td>
													<Skeleton height="medium" width="medium" />
												</td>
												<td>
													<div className="flex items-center gap-2">
														<Skeleton /> - <Skeleton />
													</div>
												</td>
												<td>
													<Skeleton />
												</td>
												<td>
													<PriceTierTable.Skeleton className="w-full" />
												</td>
											</tr>
										</>
									)}
								</tbody>
							</table>
						</Card.Body>
					</Card>
				</div>
			</Content>
		);
	},
});
