import { useSelect, useShow } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import {
	Button,
	Card,
	SelectField,
	Skeleton,
	TimeInput,
	isNumber,
	padStart,
} from '@scalingworks/react-admin-ui';
import { Content, Form } from '@scalingworks/refine-react-admin';
import { clsx } from 'clsx';
import * as React from 'react';
import { HiX } from 'react-icons/hi';

import {
	DayOfWeek,
	type PriceModifier,
	type PriceModifierInput,
	type ServiceRate,
	type ServiceRateUpdateInputV2,
	type TieredPrice,
	type TimeSlotPrice,
} from '~/api';

import { resourceNames } from '../resource-names';
import { PriceTierTable } from './components/price-tier-table';
import { useWatch } from 'react-hook-form';

export const RateSetupResourceEditPage = () => {
	const form = useForm<FormValues>({
		refineCoreProps: {
			resource: resourceNames.rateSetup,
			id: 'show',
			action: 'edit',
			metaData: {
				fields: [],
			},
		},
	});

	const {
		queryResult: { data, isLoading },
	} = useShow<ServiceRate>({
		resource: resourceNames.rateSetup,
		id: 'show',
		metaData: {
			fields: [],
		},
	});

	const selectedDefaultTieredPriceId: string | undefined = useWatch({
		control: form.control,
		name: 'defaultTieredPriceId',
	});
	const dynamicPricings: DynamicPricing[] | undefined = useWatch({
		control: form.control,
		name: 'defaults',
	});

	const [initialized, setInitialized] = React.useState(false);

	React.useEffect(() => {
		if (data) {
			form.setValue('defaultTieredPriceId', data.data.defaultTieredPriceId);
			form.setValue('defaults', priceModifiersToDynamicPricing(data.data.defaults || []));
			setInitialized(true);
		}
	}, [data]);

	const {
		options,
		queryResult: { data: selectData },
	} = useSelect<TieredPrice>({
		resource: 'tieredPrices',
		metaData: {
			fields: [
				'id',
				'name',
				{
					tiers: ['from', 'to', 'price'],
				},
				'status',
			],
		},
		optionLabel: 'name',
		optionValue: 'id',
	});

	const selectedDefaultPricing = React.useMemo(
		() =>
			selectData &&
			selectedDefaultTieredPriceId &&
			selectData.data.find((opt) => opt.id === selectedDefaultTieredPriceId),
		[options, selectedDefaultTieredPriceId]
	);

	const setDynamicPricings = (newPricings: DynamicPricing[]) =>
		form.setValue('defaults', newPricings);

	return (
		<Form
			onSubmit={form.handleSubmit((values) => {
				form.refineCore.onFinish(formValuesToUpdateInput(values as FormValues));
			})}
			form={form as any}
		>
			<Content
				title="Edit rate setup"
				extra={
					<Button
						variant="solid"
						type="submit"
						size="sm"
						loading={form.refineCore.mutationResult.isLoading}
						className="min-w-[6rem]"
					>
						Save
					</Button>
				}
			>
				<div className="flex flex-col gap-5">
					<Card>
						<Card.Header bordered>Default Pricing</Card.Header>
						<Card.Body>
							<div className="flex flex-col sm:flex-row sm:items-center gap-6">
								<div className="min-w-[20rem]">
									{/* only mount Form.SelectField when we set the initial value, else it would clear it */}
									{isLoading || !initialized ? (
										<SelectField
											value=""
											options={[{ value: '', label: 'Loading...' }]}
											name="defaultTieredPriceId"
											disabled
										/>
									) : (
										<Form.SelectField
											label="Default Pricing"
											hideLabel
											name="defaultTieredPriceId"
											options={options}
										/>
									)}
								</div>
								<div>
									{isLoading ? (
										<PriceTierTable.Skeleton className="w-full" />
									) : selectedDefaultPricing && selectedDefaultPricing.tiers ? (
										<PriceTierTable tiers={selectedDefaultPricing.tiers} className="w-full" />
									) : null}
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card>
						<Card.Header bordered>Dynamic Pricing</Card.Header>
						<div className={clsx('px-5', isLoading && 'animate-pulse')}>
							<ul>
								{dynamicPricings
									? dynamicPricings.map((price, priceIndex) => {
											return (
												<DynamicPricingItem key={price.day} day={price.day}>
													{price.slots.map((slot, slotIndex) => {
														const setSlotValue = (
															field: 'from' | 'to' | 'priceId',
															value: string
														) => {
															setDynamicPricings(
																dynamicPricings.map((currentPrice, index) =>
																	index === priceIndex
																		? {
																				...currentPrice,
																				slots: currentPrice.slots.map((currentSlot, index) =>
																					index === slotIndex
																						? {
																								...currentSlot,
																								[field]: value,
																						  }
																						: currentSlot
																				),
																		  }
																		: currentPrice
																)
															);
														};

														return (
															<div
																className="flex items-center gap-3 py-1 hover:bg-gray-50"
																key={slotIndex}
															>
																<div className="flex items-center gap-x-3 gap-y-1 flex-wrap">
																	<TimeInput
																		{...stringToTime(slot.from)}
																		onValue={(time) => setSlotValue('from', timeToString(time))}
																		required
																		aria-label="From"
																	/>
																	<span>to</span>
																	<TimeInput
																		{...stringToTime(slot.to)}
																		onValue={(time) => setSlotValue('to', timeToString(time))}
																		required
																		aria-label="To"
																	/>
																	<div className="w-[12rem]">
																		<SelectField
																			value={slot.priceId}
																			onValue={(value) => setSlotValue('priceId', value as string)}
																			options={options}
																			required
																		/>
																	</div>
																</div>
																<div>
																	<button
																		onClick={() =>
																			setDynamicPricings(
																				dynamicPricings.map((currentPrice, index) =>
																					index === priceIndex
																						? {
																								...currentPrice,
																								slots: currentPrice.slots.filter(
																									(_, index) => index !== slotIndex
																								),
																						  }
																						: currentPrice
																				)
																			)
																		}
																		type="button"
																		aria-label="Remove"
																		className="flex items-center justify-center w-11 h-11 rounded-full hover:bg-gray-200"
																	>
																		<HiX width={20} height={20} className="w-5 h-5 text-gray-500" />
																	</button>
																</div>
															</div>
														);
													})}
													<div className="py-1">
														<Button
															onClick={() =>
																setDynamicPricings(
																	dynamicPricings.map((currentPrice, index) =>
																		index === priceIndex
																			? {
																					...currentPrice,
																					slots: currentPrice.slots.concat({
																						from: currentPrice.slots[0]
																							? currentPrice.slots[0].to
																							: '',
																						to: '',
																						priceId: selectedDefaultTieredPriceId || '',
																					}),
																			  }
																			: currentPrice
																	)
																)
															}
															variant="plain"
															size="sm"
														>
															Add Slot
														</Button>
													</div>
												</DynamicPricingItem>
											);
									  })
									: days.map((day, index) => (
											<DynamicPricingItem day={day} key={day}>
												{index % 2 === 0 ? (
													<div className="flex items-center gap-x-3 gap-y-1 py-1 flex-wrap">
														<TimeInput onValue={noop} placeholder="Loading" disabled />
														<span>to</span>
														<TimeInput onValue={noop} placeholder="Loading" disabled />
														<div className="w-[12rem]">
															<SelectField
																value=""
																options={[{ value: '', label: 'Loading...' }]}
																disabled
															/>
														</div>
													</div>
												) : (
													<div className="py-1">
														<Skeleton height="medium" />
													</div>
												)}
											</DynamicPricingItem>
									  ))}
							</ul>
						</div>
					</Card>
				</div>
				<div className="flex justify-end py-3">
					<Button
						variant="solid"
						type="submit"
						size="sm"
						loading={form.refineCore.mutationResult.isLoading}
						className="min-w-[6rem]"
					>
						Save
					</Button>
				</div>
			</Content>
		</Form>
	);
};

interface DynamicPricing {
	day: DayOfWeek;
	slots: Array<Pick<TimeSlotPrice, 'from' | 'to' | 'priceId'>>;
}

const DynamicPricingItem = (props: { day: DayOfWeek; children: React.ReactNode }) => {
	return (
		<li className="flex flex-col md:flex-row md:gap-3 py-1 border-b border-gray-200 last:border-b-0">
			<div className="text-lg w-28 py-1 md:py-3 flex-shrink-0 font-medium">{props.day}</div>
			<div className="flex flex-col items-start gap-2 py-1">{props.children}</div>
		</li>
	);
};

interface FormValues {
	defaults: DynamicPricing[];
	defaultTieredPriceId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const days = [
	DayOfWeek.Monday,
	DayOfWeek.Tuesday,
	DayOfWeek.Wednesday,
	DayOfWeek.Thursday,
	DayOfWeek.Friday,
	DayOfWeek.Saturday,
	DayOfWeek.Sunday,
];

const stringToTime = (
	value: string | undefined
): {
	hours?: number;
	minutes?: number;
	seconds?: number;
} => {
	if (!value) {
		return {};
	}
	const [hh, mm, ss] = value.split(':');
	const hours = Number(hh);
	const minutes = Number(mm);
	const seconds = Number(ss);

	return {
		hours: Number.isNaN(hours) ? undefined : hours,
		minutes: Number.isNaN(minutes) ? undefined : minutes,
		seconds: Number.isNaN(seconds) ? undefined : seconds,
	};
};

const timeToString = (
	time:
		| {
				hours: number;
				minutes: number;
				seconds?: number;
		  }
		| undefined
): string =>
	time
		? `${padStart(time.hours.toString(), 2, '0')}:${padStart(time.minutes.toString(), 2, '0')}${
				isNumber(time.seconds) ? `:${padStart(time.seconds.toString(), 2, '0')}` : ''
		  }`
		: '';

const formValuesToUpdateInput = (values: FormValues): ServiceRateUpdateInputV2 => {
	const priceModifiers: Array<PriceModifierInput> = [];

	values.defaults.forEach((pricing) => {
		if (pricing.slots.length > 0) {
			priceModifiers.push({
				days: [pricing.day],
				timeSlotPrices: pricing.slots.map((slot) => ({
					from: slot.from,
					to: slot.to,
					priceId: slot.priceId,
				})),
			});
		}
	});

	return {
		defaultTieredPriceId: values.defaultTieredPriceId,
		defaults: priceModifiers,
	};
};

const priceModifiersToDynamicPricing = (
	priceModifiers: Array<PriceModifier>
): Array<DynamicPricing> => {
	const map = new Map(days.map((day) => [day, [] as DynamicPricing['slots']]));

	priceModifiers.forEach((modifier) => {
		modifier.days?.forEach((day) => {
			const currentSlots = map.get(day);
			if (currentSlots) {
				modifier.timeSlotPrices.forEach((slotPrice) => {
					currentSlots.push(slotPrice);
				});
			}
		});
	});

	return Array.from(map, ([day, slots]) => ({ day, slots }));
};
