import { useCustom, useNavigation, useRouterContext } from '@refinedev/core';
import { Avatar, Button, Card, Skeleton, formatDateUtc } from '@scalingworks/react-admin-ui';
import { Content, createHelpers } from '@scalingworks/refine-react-admin';
import { endOfDay, startOfDay } from 'date-fns';
import * as React from 'react';

import {
	type Customer,
	type DashboardReport,
	type Order,
	type OrderStatus,
	type ServiceProvider,
} from '~/api';
import { OrderStatusTag } from '~/components/order-status-tag';
import { resourceNames } from '~/resources/resource-names';
import { CLIENT_TIMEZONE } from '../lib/utils';

const orderHelpers = createHelpers<Order>({
	resourceName: resourceNames.order,
});

const driverHelpers = createHelpers<ServiceProvider>({
	resourceName: resourceNames.driver,
});

const dashboardHelpers = createHelpers<DashboardReport>({
	resourceName: '',
});

const RecentOrders = orderHelpers.createTableComponent({
	fields: [
		'id',
		'orderNumber',
		'status',
		'statusDisplay',
		{
			customer: ['id', 'fullName'],
		},
	],
	columns: ({ LinkToDetails }) => [
		{
			id: 'id',
			accessorKey: 'id',
			header: 'Order #',
			cell: (data) => {
				const id = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={id} className="font-semibold">
						{data.row.original.orderNumber}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'status',
			accessorKey: 'status',
			cell: (data) => {
				const status = data.cell.getValue<OrderStatus>();
				const statusText = data.row.original.statusDisplay;
				return <OrderStatusTag status={status} displayText={statusText} />;
			},
		},
		{
			id: 'customer',
			accessorFn: (row) => row.customer,
			header: 'Customer',
			cell: (data) => {
				const customer = data.cell.getValue<Customer>();

				return customer ? (
					<LinkToDetails resourceName={resourceNames.customer} resourceId={customer.id}>
						{customer.fullName}
					</LinkToDetails>
				) : (
					'-'
				);
			},
		},
	],
	defaultSorter: [
		{
			field: 'createdAt',
			order: 'desc',
		},
	],
	hidePagination: true,
});

const TopDrivers = driverHelpers.createTableComponent({
	fields: ['id', 'profilePicture', 'fullName', 'ordersCount'],
	columns: ({ LinkToDetails }) => [
		{
			id: 'name',
			accessorKey: 'fullName',
			header: 'Full name',
			enableSorting: false,
			cell: (data) => {
				const name = data.cell.getValue<string>();
				const profileImage = data.row.original.profilePicture;
				const id = data.row.original.id;

				return (
					<LinkToDetails className="flex items-center gap-2 group/name" resourceId={id}>
						<Avatar
							text={name}
							imgSrc={profileImage || undefined}
							className="flex-shrink-0 transition will-change-transform group-hover/name:scale-125"
						/>
						<span className="font-semibold">{name}</span>
					</LinkToDetails>
				);
			},
		},
		{
			id: 'ordersCount',
			accessorKey: 'ordersCount',
			header: 'Orders',
		},
	],
	defaultSorter: [
		{
			field: 'ordersCount',
			order: 'desc',
		},
	],
	hidePagination: true,
});

export const DashboardPage = () => {
	const { Link } = useRouterContext();
	const { listUrl } = useNavigation();

	const dates = React.useMemo(() => {
		const now = new Date();

		return {
			startTime: formatDateUtc(startOfDay(now)),
			endTime: formatDateUtc(endOfDay(now)),
			timezone: CLIENT_TIMEZONE,
		};
	}, []);

	const { data, isLoading } = useCustom<DashboardReport>({
		method: 'get',
		url: '',
		metaData: {
			operation: 'dashboardReport',
			variables: {
				startTime: {
					type: 'DateTime',
					required: true,
					value: dates.startTime,
				},
				endTime: {
					type: 'DateTime',
					required: true,
					value: dates.endTime,
				},
				timezone: {
					type: 'String',
					value: dates.timezone,
				},
			},
			fields: dashboardHelpers.defineFields([
				'totalNewCustomer',
				'totalOrder',
				'totalRevenue',
			]) as any[],
		},
	});

	return (
		<Content title="Business Overview">
			<div className="@container/stats">
				<section className="grid @3xl/stats:grid-cols-3 gap-5">
					<Card>
						<Card.Body>
							<div>Today's Revenue</div>
							<div className="flex justify-between items-end gap-2 mt-1">
								<div className="text-2xl font-semibold">
									{data?.data.totalRevenue || (isLoading && <Skeleton heightClass="h-8" />)}
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>
							<div>Today's Orders</div>
							<div className="flex justify-between items-end gap-2 mt-1">
								<div className="text-2xl font-semibold">
									{isLoading ? <Skeleton heightClass="h-8" /> : data?.data.totalOrder || 0}
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>
							<div>New Users</div>
							<div className="flex justify-between items-end gap-2 mt-1">
								<div className="text-2xl font-semibold">
									{isLoading ? <Skeleton heightClass="h-8" /> : data?.data.totalNewCustomer || 0}
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="@3xl/stats:col-span-2">
						<Card.Header
							extra={
								<Button
									variant="default"
									size="sm"
									render={(btnProps) => <Link {...btnProps} to={listUrl(resourceNames.order)} />}
								>
									View Orders
								</Button>
							}
						>
							Latest Orders
						</Card.Header>
						<RecentOrders />
					</Card>
					<Card>
						<Card.Header
							extra={
								<Button
									variant="default"
									size="sm"
									render={(btnProps) => <Link {...btnProps} to={listUrl(resourceNames.driver)} />}
								>
									View Drivers
								</Button>
							}
						>
							Top Drivers
						</Card.Header>
						<TopDrivers />
					</Card>
				</section>
			</div>
		</Content>
	);
};
