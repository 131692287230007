import { useCustom } from '@refinedev/core';
import {
	Avatar,
	SearchableSelectField,
	Switch,
	Tag,
	TextField,
	assertExists,
	formatDate,
} from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import * as React from 'react';
import { HiOutlineHand, HiStatusOffline, HiStatusOnline } from 'react-icons/hi';

import {
	type Customer,
	type Order,
	type OrderStatus,
	QueryMode,
	type ServiceProvider,
	ServiceProviderStatus,
	SortOrder,
	getSdk,
	ServiceProviderType,
} from '~/api';
import { OrderStatusTag } from '~/components/order-status-tag';

import { resourceNames } from './resource-names';

const {
	defineDefaults,
	defineFields,
	defineCardSection,
	defineRelatedResourceSection,
	defineControlComponents,
	defineControl,
	defineStatsSection,
	defineTabsSection,
	defineShowPage,
} = createHelpers<ServiceProvider>({
	resourceName: resourceNames.driver,
});

const addressDefaults = defineDefaults({
	address: {
		line1: '',
		line2: '',
		city: '',
		state: '',
		postalCode: '',
		country: 'Malaysia',
	},
});

const vehicleDefaults = defineDefaults({
	vehicle: {
		vehicleMake: '',
		vehicleModel: '',
		licensePlate: '',
		vehicleColor: '',
		vehicleRegistrationDate: '',
		attachments: [],
	},
});

const addressControls = defineControlComponents({
	'address.state': defineControl<'address.state'>({
		label: 'State',
		component: function StateControl(props) {
			const countryValue = props.useWatch({
				name: 'address.country',
			});
			const { data } = useCustom<Array<{ name: string }>>({
				method: 'get',
				url: '',
				metaData: {
					operation: 'states',
					fields: ['name'],
					variables: {
						where: {
							type: 'StateWhereInput',
							value: {
								...(countryValue
									? {
											country: {
												is: {
													name: {
														equals: countryValue,
													},
												},
											},
									  }
									: {}),
							},
						},
					},
				},
			});

			return !countryValue || !data ? (
				<TextField label="State" disabled />
			) : (
				<SearchableSelectField
					value={props.value || ''}
					onValue={props.setValue}
					options={data.data.map((state) => ({
						label: state.name,
						value: state.name,
					}))}
					label="State"
					disabled={props.disabled}
					required={props.required}
					allowFreeText
				/>
			);
		},
	}),
	'address.country': defineControl<'address.country'>({
		label: 'Country',
		component: function CountryControl(props) {
			const [inputValue, setInputValue] = React.useState(props.value || '');
			const { data } = useCustom<Array<{ name: string }>>({
				method: 'get',
				url: '',
				metaData: {
					operation: 'countries',
					fields: ['name'],
					variables: inputValue
						? {
								where: {
									type: 'CountryWhereInput',
									value: {
										name: {
											contains: inputValue,
											mode: QueryMode.Insensitive,
										},
									},
								},
						  }
						: {},
				},
			});
			return (
				<SearchableSelectField
					options={data?.data.map((country) => ({
						label: country.name,
						value: country.name,
					}))}
					label="Country"
					value={props.value || ''}
					onValue={props.setValue}
					disabled={props.disabled}
					required={props.required}
					onInputValueChange={setInputValue}
					placeholder="Search"
					status={props.error ? 'danger' : undefined}
				/>
			);
		},
	}),
});

const vehicleControls = defineControlComponents({
	'vehicle.vehicleMake': defineControl({
		label: 'Make',
		component: function VehicleMakeControl(props) {
			const [inputValue, setInputValue] = React.useState(props.value || '');
			const { data } = useCustom<Array<{ name: string }>>({
				method: 'get',
				url: '',
				metaData: {
					operation: 'vehicleMakes',
					fields: ['name'],
					variables: {
						where: {
							type: 'VehicleMakeWhereInput',
							value: inputValue
								? {
										name: {
											contains: inputValue,
											mode: QueryMode.Insensitive,
										},
								  }
								: {
										isPopular: {
											equals: true,
										},
								  },
						},
						orderBy: {
							type: 'VehicleMakeOrderByWithRelationInput!',
							list: true,
							value: [{ isPopular: SortOrder.Desc }, { name: SortOrder.Asc }],
						},
					},
				},
			});
			return (
				<SearchableSelectField
					options={data?.data.map((make) => ({
						label: make.name,
						value: make.name,
					}))}
					label="Make"
					value={props.value}
					onValue={props.setValue}
					disabled={props.disabled}
					required={props.required}
					onInputValueChange={setInputValue}
					placeholder="Search"
					status={props.error ? 'danger' : undefined}
				/>
			);
		},
	}),
	'vehicle.vehicleRegistrationDate': {
		type: 'date',
		config: {
			label: 'Registration date',
			monthNavigation: 'dropdown',
		},
	},
	'vehicle.attachments': {
		type: 'filelist',
		config: {
			label: 'Attachments',
			purpose: 'Attachment',
			fileType: 'image',
			required: false,
		},
	},
});

const idControls = defineControlComponents({
	'identityDocument.identityType': {
		type: 'select',
		config: {
			label: 'ID Type',
			options: [
				{
					label: 'New IC',
					value: 'New IC',
				},
				{
					label: 'Passport',
					value: 'Passport',
				},
			],
		},
	},
	'identityDocument.identityNumber': {
		type: 'text',
		config: {
			label: 'ID Number',
		},
	},
	'identityDocument.issueDate': {
		type: 'date',
		config: {
			label: 'Issue date',
			required: false,
			monthNavigation: 'dropdown',
		},
	},
	'identityDocument.expiryDate': {
		type: 'date',
		config: {
			label: 'Expiry date',
			required: false,
			monthNavigation: 'dropdown',
		},
	},
	'identityDocument.attachments': {
		type: 'filelist',
		config: {
			label: 'Identity documents upload',
			purpose: 'identityDocument',
			allowChangeAlt: true,
			required: false,
		},
	},
});

const bankAccountDefaults = defineDefaults({
	bankAccount: {
		accountNumber: '',
		accountHolderName: '',
		bankName: '',
		swiftCode: '',
	},
});

const emergencyContactDefaults = defineDefaults({
	emergencyContact: {
		name: '',
		phoneNumber: '',
		relationship: '',
	},
});

export const serviceProviderResource = createResource({
	name: resourceNames.driver,
	label: 'Drivers',
	icon: <HiOutlineHand />,
	fields: defineFields([
		'id',
		'fullName',
		'profilePicture',
		'mobileNumber',
		'email',
		'ordersCount',
		'status',
		'isOnDuty',
	]),
	defaultValues: {
		firstName: '',
		lastName: '',
		mobileNumber: '',
		email: '',
		password: '',
		...addressDefaults,
		...vehicleDefaults,
		...bankAccountDefaults,
		...emergencyContactDefaults,
	},
	dataProvider: {
		create: ({ client, variables }) =>
			getSdk(client)
				.createServiceProvider({
					data: variables,
				})
				.then((res) => ({
					data: res.createOneServiceProvider,
				})),
		update: ({ client, variables, id }) =>
			getSdk(client)
				.updateServiceProvider({
					data: variables,
					where: {
						id: id as string,
					},
				})
				.then((res) => {
					assertExists(res.updateOneServiceProvider);

					return {
						data: res.updateOneServiceProvider,
					};
				}),
	},
	controls: {
		components: ({ action }) => ({
			...addressControls,
			...vehicleControls,
			...idControls,
			mobileNumber: {
				type: 'phone',
				config: {
					label: 'Mobile number',
				},
			},
			password:
				action === 'edit'
					? false
					: {
							type: 'password',
							config: {
								label: 'Password',
								autoComplete: 'new-password',
							},
					  },
		}),
		sections: [
			{
				title: 'General',
				items: ['firstName', 'lastName', 'mobileNumber', 'email', 'password'],
				componentConfigDefaults: {
					required: true,
				},
			},
			{
				title: 'Address',
				items: [
					'address.line1',
					'address.line2',
					'address.city',
					'address.state',
					'address.postalCode',
					'address.country',
				],
			},
			{
				title: 'Vehicle',
				items: [
					'vehicle.vehicleMake',
					'vehicle.vehicleModel',
					'vehicle.licensePlate',
					'vehicle.vehicleColor',
					'vehicle.vehicleRegistrationDate',
					{
						field: 'vehicle.attachments',
						span: 2,
					},
				],
			},
			{
				title: 'Identification Documents',
				items: [
					'identityDocument.identityType',
					'identityDocument.identityNumber',
					'identityDocument.issueDate',
					'identityDocument.expiryDate',
					{ field: 'identityDocument.attachments', span: 2 },
				],
			},
			{
				title: 'Bank Account',
				items: [
					'bankAccount.accountNumber',
					'bankAccount.accountHolderName',
					'bankAccount.bankName',
					'bankAccount.swiftCode',
				],
			},
			{
				title: 'Emergency Contact',
				items: [
					'emergencyContact.name',
					'emergencyContact.phoneNumber',
					'emergencyContact.relationship',
				],
			},
		],
	},
	show: defineShowPage({
		title: (data) => (
			<div className="inline-flex items-center gap-2">
				<h1 className="text-2xl">{data.fullName}</h1>
				<Tag color={colorByStatus[data.status]}>{data.status}</Tag>
				{data.isOnDuty ? (
					<Tag color="green">
						<HiStatusOnline />
						Online
					</Tag>
				) : (
					<Tag color="gray">
						<HiStatusOffline />
						Offline
					</Tag>
				)}
			</div>
		),
		sections: [
			defineStatsSection({
				fields: [
					{ totalEarnings: ['formattedAmount'] },
					'profilePicture',
					'ordersCount',
					{ wallet: [{ balance: ['formattedAmount'] }] },
					'rating',
					'ratingCount',
				],
				imageField: 'profilePicture',
				stats: [
					{
						label: 'Total Earnings',
						value: (data) => data.totalEarnings.formattedAmount,
					},
					{
						label: 'Orders',
						value: (data) => data.ordersCount,
					},
					{
						label: 'Rating',
						value: (data) => `${data.ratingCount > 0 ? `${Number(data.rating).toFixed(1)} (${data.ratingCount})` : '-'}`,
					},
					// {
					// 	label: 'Wallet Balance',
					// 	value: (data) => data.wallet?.balance.formattedAmount,
					// },
				],
				allowChangeImage: true,
			}),
			defineTabsSection({
				tabs: [
					{
						label: 'Details',
						content: [
							defineCardSection({
								title: 'General',
								fields: [
									'firstName',
									'lastName',
									'mobileNumber',
									'email',
									'type',
									'status',
									'createdAt',
									'updatedAt',
								],
								editControls: {
									mobileNumber: {
										type: 'phone',
										config: {
											label: 'Mobile number',
										},
									},
									type: {
										type: 'select',
										config: {
											label: 'Type',
											options: Object.entries(ServiceProviderType).map(([label, value]) => ({
												label,
												value,
											})),
										},
									},
									status: {
										type: 'select',
										config: {
											label: 'Status',
											options: Object.entries(ServiceProviderStatus).map(([label, value]) => ({
												label,
												value,
											})),
										},
									},
									rating: false,
									createdAt: false,
									updatedAt: false,
								},
								componentConfigDefaults: {
									required: true,
								},
								displays: {
									createdAt: {
										label: 'Created at',
										type: 'date',
										formatType: 'dateAndTime',
									},
									updatedAt: {
										label: 'Updated at',
										type: 'date',
										formatType: 'dateAndTime',
									},
								},
							}),
							defineCardSection({
								title: 'Address',
								fields: [
									{
										address: ['line1', 'line2', 'city', 'state', 'postalCode', 'country'],
									},
								],
								editControls: {
									...addressControls,
									'address.line1': {
										type: 'text',
										config: {
											label: 'Line 1',
										},
									},
									'address.line2': {
										type: 'text',
										config: {
											label: 'Line 2',
											required: false,
										},
									},
								},
								displays: {
									'address.line1': {
										label: 'Line 1',
										render: (data) => data.address?.line1,
									},
									'address.line2': {
										label: 'Line 2',
										render: (data) => data.address?.line2,
									},
								},
								defaultValues: addressDefaults,
								componentConfigDefaults: {
									required: true,
								},
							}),
							defineCardSection({
								title: 'Vehicle',
								fields: [
									{
										vehicle: [
											'vehicleMake',
											'vehicleModel',
											'licensePlate',
											'vehicleColor',
											'vehicleRegistrationDate',
											{
												attachments: ['id', 'url', 'alt'],
											},
										],
									},
								],
								defaultValues: vehicleDefaults,
								editControls: vehicleControls,
								componentConfigDefaults: {
									required: true,
								},
								displays: {
									'vehicle.vehicleRegistrationDate': {
										label: 'Registration date',
										type: 'date',
										formatType: 'dateOnly',
									},
									'vehicle.attachments': {
										label: 'Images',
										type: 'imageList',
										span: 2,
									},
								},
							}),
							defineCardSection({
								title: 'Identification Documents',
								fields: [
									{
										identityDocument: [
											'identityType',
											'identityNumber',
											'issueDate',
											'expiryDate',
											{
												attachments: ['id', 'url', 'alt'],
											},
										],
									},
								],
								displays: {
									'identityDocument.attachments': {
										label: '',
										type: 'imageList',
										showInfo: true,
									},
								},
								editControls: idControls,
								componentConfigDefaults: {
									required: true,
								},
							}),
							defineCardSection({
								title: 'Bank Account',
								fields: [
									{
										bankAccount: ['accountNumber', 'accountHolderName', 'bankName', 'swiftCode'],
									},
								],
								defaultValues: bankAccountDefaults,
								componentConfigDefaults: {
									required: true,
								},
							}),
							defineCardSection({
								title: 'Emergency Contact',
								fields: [
									{
										emergencyContact: ['name', 'phoneNumber', 'relationship'],
									},
								],
								defaultValues: emergencyContactDefaults,
								componentConfigDefaults: {
									required: true,
								},
							}),
						],
					},
					{
						label: 'Orders',
						content: [
							defineRelatedResourceSection<Order>({
								relatedResourceName: resourceNames.order,
								fields: [
									'id',
									'orderNumber',
									'scheduledPickupAt',
									'status',
									'createdAt',
									{
										customer: ['id', 'fullName', 'mobileNumber'],
									},
								],
								allowSearch: true,
								getInitialFilter: ({ id }) => [
									{
										field: 'serviceProviderId',
										value: id,
										operator: 'eq',
									},
								],
								columns: ({ LinkToDetails }) => [
									{
										id: 'id',
										accessorKey: 'id',
										header: 'Order #',
										cell: (data) => {
											const id = data.cell.getValue<string>();

											return (
												<LinkToDetails resourceId={id} className="font-semibold">
													{data.row.original.orderNumber}
												</LinkToDetails>
											);
										},
									},
									{
										id: 'createdAt',
										accessorKey: 'createdAt',
										header: 'Order date',
										cell: (data) => {
											const dateValue = data.cell.getValue<string>();

											return dateValue && formatDate(dateValue);
										},
									},
									{
										id: 'customer',
										accessorFn: (row) => row.customer,
										header: 'Customer',
										cell: (data) => {
											const customer = data.cell.getValue<Customer>();

											return customer ? (
												<LinkToDetails
													resourceName={resourceNames.customer}
													resourceId={customer.id}
												>
													{customer.fullName}
													<br />
													{customer.mobileNumber}
												</LinkToDetails>
											) : (
												'-'
											);
										},
									},
									{
										id: 'status',
										accessorKey: 'status',
										cell: (data) => <OrderStatusTag status={data.cell.getValue<OrderStatus>()} />,
									},
								],
							}),
						],
					},
					// {
					// 	label: 'Transactions',
					// 	content: [],
					// },
				],
			}),
		],
	}),
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	allowSearch: true,
	allowEdit: false,
	columns: ({ useUpdate, LinkToDetails }) => [
		{
			id: 'name',
			accessorKey: 'fullName',
			header: 'Full name',
			enableSorting: false,
			cell: (data) => {
				const name = data.cell.getValue<string>();
				const profileImage = data.row.original.profilePicture;
				const id = data.row.original.id;

				return (
					<LinkToDetails className="flex items-center gap-2 group/name" resourceId={id}>
						<Avatar
							text={name}
							imgSrc={profileImage || undefined}
							className="flex-shrink-0 transition will-change-transform group-hover/name:scale-125"
						/>
						<span className="font-semibold">{name}</span>
					</LinkToDetails>
				);
			},
		},
		{
			id: 'mobileNumber',
			accessorKey: 'mobileNumber',
			header: 'Phone number',
		},
		{
			id: 'email',
			accessorKey: 'email',
			header: 'Email',
		},
		{
			id: 'ordersCount',
			accessorKey: 'ordersCount',
			header: 'Total orders',
		},
		{
			id: 'toggleOnDuty',
			header: 'On duty',
			accessorKey: 'isOnDuty',
			cell: (data) => {
				const isOnDuty = data.cell.getValue<boolean>();
				const fullName = data.row.original.fullName;

				const { mutate, isLoading } = useUpdate();

				return (
					<Switch
						checked={isOnDuty}
						onCheckedChange={(checked) => {
							mutate(
								{
									changes: {
										isOnDuty: checked,
									},
									id: data.row.original.id,
								},
								{
									successMessage: checked
										? `${fullName} is on duty now`
										: `${fullName} is off duty now`,
								}
							);
						}}
						isLoading={isLoading}
					/>
				);
			},
		},
	],
});

const colorByStatus = {
	Active: 'green',
	Inactive: 'gray',
	Pending: 'amber',
} as const;
