import { Avatar, Tag, formatDate } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { FiUser } from 'react-icons/fi';

import {
	type Customer,
	CustomerStatus,
	type Order,
	type OrderStatus,
	type Review,
	type ServiceProvider,
} from '~/api';
import { OrderStatusTag } from '~/components/order-status-tag';

import { resourceNames } from './resource-names';

const {
	defineFields,
	defineCardSection,
	defineStatsSection,
	defineTabsSection,
	defineRelatedResourceSection,
	defineShowPage,
} = createHelpers<Customer>({
	resourceName: resourceNames.customer,
});

export const customerResource = createResource({
	name: resourceNames.customer,
	label: 'Passengers',
	icon: <FiUser />,
	fields: defineFields([
		'id',
		'fullName',
		'mobileNumber',
		'email',
		'ordersCount',
		'status',
		'profilePicture',
	]),
	filterControls: {
		fullName: {
			type: 'text',
			config: {
				label: 'Name',
			},
			operator: 'contains',
		},
		mobileNumber: {
			type: 'text',
			config: {
				label: 'Phone number',
			},
			operator: 'contains',
		},
		email: {
			type: 'text',
			config: {
				label: 'Email',
			},
			operator: 'contains',
		},
		status: {
			type: 'multiselect',
			config: {
				label: 'Status',
				options: Object.entries(CustomerStatus).map(([label, value]) => ({
					label,
					value,
				})),
				placeholder: 'All statuses',
			},
		},
	},
	defaultValues: {
		fullName: '',
		mobileNumber: '',
		email: '',
	},
	allowCreate: false,
	allowEdit: false,
	allowSearch: true,
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	columns: ({ LinkToDetails }) => [
		{
			id: 'fullName',
			accessorKey: 'fullName',
			header: 'Full name',
			enableSorting: true,
			cell: (data) => {
				const name = data.cell.getValue<string>();
				const profileImage = data.row.original.profilePicture;
				const id = data.row.original.id;

				return (
					<LinkToDetails resourceId={id} className="flex items-center gap-2 group/name">
						<Avatar
							text={name}
							imgSrc={profileImage || ''}
							className="flex-shrink-0 transition will-change-transform group-hover/name:scale-125"
						/>
						<span className="font-semibold">{name}</span>
					</LinkToDetails>
				);
			},
		},
		{
			id: 'mobileNumber',
			accessorKey: 'mobileNumber',
			header: 'Phone number',
			enableSorting: true,
		},
		{
			id: 'email',
			accessorKey: 'email',
			header: 'Email',
			enableSorting: true,
		},
		{
			id: 'ordersCount',
			accessorKey: 'ordersCount',
			header: 'Total orders',
			enableSorting: true,
		},
		{
			id: 'status',
			accessorKey: 'status',
		},
	],
	show: defineShowPage({
		title: (data) => (
			<div className="inline-flex items-center gap-2">
				<h1 className="text-2xl">{data.fullName}</h1>
				<Tag color={colorByStatus[data.status]}>{data.status}</Tag>
			</div>
		),
		sections: [
			defineStatsSection({
				fields: [
					'profilePicture',
					'ordersCount',
					{ totalSpent: ['formattedAmount'] },
					{ wallets: [{ balance: ['formattedAmount'] }] },
				],
				imageField: 'profilePicture',
				stats: [
					{
						label: 'Amount Spent',
						value: (data) => data.totalSpent.formattedAmount,
					},
					{
						label: 'Orders',
						value: (data) => data.ordersCount,
					},
					{
						label: 'Wallet Balance',
						value: (data) => data.wallets[0]?.balance.formattedAmount,
					},
				],
				allowChangeImage: true,
			}),
			defineTabsSection({
				tabs: [
					{
						label: 'Details',
						content: [
							defineCardSection({
								title: 'General',
								fields: ['fullName', 'status', 'mobileNumber', 'email', 'createdAt', 'updatedAt'],
								displays: {
									createdAt: {
										label: 'Created at',
										type: 'date',
										formatType: 'dateAndTime',
									},
									updatedAt: {
										label: 'Updated at',
										type: 'date',
										formatType: 'dateAndTime',
									},
								},
							}),
						],
					},
					{
						label: 'Orders',
						content: [
							defineRelatedResourceSection<Order>({
								relatedResourceName: resourceNames.order,
								fields: [
									'id',
									'orderNumber',
									'scheduledPickupAt',
									'status',
									'createdAt',
									{
										serviceProvider: ['id', 'fullName', 'mobileNumber'],
									},
								],
								getInitialFilter: ({ id }) => [
									{
										field: 'customerId',
										operator: 'eq',
										value: id,
									},
								],
								columns: ({ LinkToDetails }) => [
									{
										id: 'id',
										accessorKey: 'id',
										header: 'Order #',
										cell: (data) => {
											const id = data.cell.getValue<string>();

											return (
												<LinkToDetails resourceId={id} className="font-semibold">
													{data.row.original.orderNumber}
												</LinkToDetails>
											);
										},
									},
									{
										id: 'createdAt',
										accessorKey: 'createdAt',
										header: 'Order date',
										cell: (data) => {
											const dateValue = data.cell.getValue<string>();

											return dateValue && formatDate(dateValue);
										},
									},
									{
										id: 'driver',
										accessorFn: (row) => row.serviceProvider,
										header: 'Driver',
										cell: (data) => {
											const driver = data.cell.getValue<ServiceProvider>();

											return driver ? (
												<LinkToDetails resourceName={resourceNames.driver} resourceId={driver.id}>
													{driver.fullName}
													<br />
													{driver.mobileNumber}
												</LinkToDetails>
											) : (
												'-'
											);
										},
									},
									{
										id: 'status',
										accessorKey: 'status',
										cell: (data) => <OrderStatusTag status={data.cell.getValue<OrderStatus>()} />,
									},
								],
							}),
						],
					},
					{
						label: 'Reviews',
						content: [
							defineRelatedResourceSection<Review>({
								relatedResourceName: resourceNames.reviews,
								fields: [
									'id',
									'comment',
									'rating',
									'createdAt',
									{
										order: ['id', 'orderNumber'],
										serviceProvider: ['id', 'fullName', 'mobileNumber'],
									},
								],
								getInitialFilter: ({ id }) => [
									{
										field: 'customerId',
										operator: 'eq',
										value: id,
									},
								],
								columns: ({ LinkToDetails }) => [
									{
										id: 'rating',
										accessorKey: 'rating',
									},
									{
										id: 'comment',
										accessorKey: 'comment',
									},
									{
										id: 'id',
										accessorKey: 'id',
										header: 'Order #',
										cell: (data) => {
											const order = data.row.original.order;

											return (
												<LinkToDetails resourceId={order.id} resourceName={resourceNames.order}>
													{order.orderNumber}
												</LinkToDetails>
											);
										},
									},
									{
										id: 'createdAt',
										accessorKey: 'createdAt',
										header: 'Order date',
										cell: (data) => {
											const dateValue = data.cell.getValue<string>();

											return dateValue && formatDate(dateValue);
										},
									},
									{
										id: 'driver',
										accessorFn: (row) => row.serviceProvider,
										header: 'Driver',
										cell: (data) => {
											const driver = data.cell.getValue<ServiceProvider>();

											return driver ? (
												<LinkToDetails resourceName={resourceNames.driver} resourceId={driver.id}>
													{driver.fullName}
													<br />
													{driver.mobileNumber}
												</LinkToDetails>
											) : (
												'-'
											);
										},
									},
								],
							}),
						],
					},
				],
			}),
		],
	}),
});

const colorByStatus = {
	Active: 'green',
	Blocked: 'red',
	Deleted: 'gray',
} as const;
