export const resourceNames = {
	adminRole: 'admin-roles',
	adminUser: 'admin-users',
	cancellationReason: 'cancellation-reasons',
	customer: 'customers',
	coupons: 'discount-coupons',
	driver: 'service-providers',
	order: 'orders',
	reviews: 'reviews',
	rateSetup: 'service-rate',
	tierPrice: 'tiered-prices',
} as const;
