import { useNavigation } from '@refinedev/core';
import { assertExists } from '@scalingworks/react-admin-ui';
import { createResource, defineCustomPage } from '@scalingworks/refine-react-admin';
import * as React from 'react';

import { type ServiceRate, getSdk } from '~/api';

import { resourceNames } from '../resource-names';
import { RateSetupResourceEditPage } from './rate-setup-resource-edit-page';
import { rateSetupResourceShowPage } from './rate-setup-resource-show-page';

function ServiceRateListing() {
	const navigateTo = useNavigation();

	React.useEffect(() => {
		navigateTo.show(resourceNames.rateSetup, 'show', 'replace');
	}, []);

	return null;
}

export const rateSetupResource = createResource<ServiceRate>({
	name: resourceNames.rateSetup,
	label: 'Rate Setup',
	fields: [],
	columns: () => [],
	defaultValues: {},
	allowCreate: false,
	dataProvider: {
		getOne: ({ client }) =>
			getSdk(client)
				.serviceRate()
				.then((res) => ({
					data: res.serviceRate as any,
				})),
		update: ({ client, variables }) =>
			getSdk(client)
				.updateServiceRate({ data: variables as any })
				.then((res) => {
					assertExists(res.updateOneServiceRate);
					return {
						data: res.updateOneServiceRate,
					};
				}),
	},
	list: defineCustomPage({
		title: 'Rate Setup',
		render: () => <ServiceRateListing />,
	}),
	show: rateSetupResourceShowPage,
	edit: defineCustomPage({
		title: 'Edit Rate Setup',
		render: () => <RateSetupResourceEditPage />,
	}),
});
